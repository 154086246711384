
import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, HStack, Switch} from "@chakra-ui/react"; 
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import Typewriter from 'typewriter-effect';
import {   HK3, HK4, HK5 } from "../../../components/heading/HeadingKalyst";
import { SendForm } from "../../../components/form/SendForm";
import { object,string } from 'yup'; 
import { TextK1 } from "../../../components/text/TextKalyst"; 
import { useEffect, useState } from "react"; 
import { getWebsite } from "../../../theme/PageConfig";
import { Navigate, useLocation } from "react-router-dom"; 
import DadosScript, { removeScript } from "../../../theme/ADSscript";
import { sendFormSocket } from "../../../components/session/SessionKalyst";
export function PagePainelRegister(props){
    const location = useLocation();   
    const { colorMode ,toggleColorMode} = useColorMode();
    const [redirect,setRedirect] = useState(null); 
    const [indicacao,setIndicacao]= useState(null)
    function buscarIndicao(){  
        if(indicacao==null){ 
            var pag=[];
            (location.pathname+'/').replace('//','/').split('/').forEach((path)=>{
                if(path!==''){
                    pag.push(path);
                }
            });   
            if(typeof pag[1] !== 'undefined'){  
                sendFormSocket({ modulo:'user',cmd:'affiliate',form:{id:pag[1]},callback:(result)=>{    
                    setIndicacao(pag[1]);
                }});
            } 
            setIndicacao('');
        } 
    }
    
    useEffect(() => { 
        buscarIndicao();  
        DadosScript(getWebsite().url, 'registerGoogle'); 
        return () => {
            removeScript(getWebsite().url+'_registerGoogle'); 
        } 
      }, []); 
    return <Flex zIndex={2} w='100vw' bg={'bgLoginLight.300'}  _dark={{bg:'bgLoginDark.100'}} > 
             <Flex zIndex={3} w='full' align={'center'} justify='center'   minH={'100vh'}   margin = '0 auto' >
                <Flex w={{base:'100vw'}} maxW = 'calc(100%)' minH={{base:'auto',md:'100%'}}    borderRadius={'5px'}>
                <Stack display={{base:'none',lg:'flex'}} w={{base:'50%'}} p='0' position='relative' align={'flex-end'} justify='center' >
                        <Image zIndex={1} position={'absolute'}  bottom='10px' left='0px'  w={{base:'200px',lg:'300px'}} src={'/web/'+getWebsite().url+'/avatar.png'}   alt='avatar'    borderRadius={'5px'}/>
                        <Stack zIndex={2} w='100%' pt='120px' align={'center'} justify='flex-start' maxW={'440px'} h='100%'    gap={'15px'}>
                            <Image zIndex={1}   h='180px'  src={'/web/'+getWebsite().url+'/banner.png'} alt='login'    borderRadius={'5px'}/> 
                            <HK5  fontWeight={'bold'} color='textLight.400' _dark={{color:'textDark.200'}}  noOfLines={3} textAlign='right'>COMUNIDADE</HK5>
                            <Flex>
                                <HK3  fontWeight={'bold'} color='textLight.400' _dark={{color:'textDark.300'}}> 
                                    <Typewriter 
                                        options={{
                                            strings: ['RENDA', 'GANHE', 'PROSPERE'],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                    />  
                                </HK3>  
                                <HK3 color='white' fontWeight={'bold'}> 
                                  MAIS!
                                </HK3> 
                            </Flex>
                            
                        </Stack>
                    </Stack>
                    <Stack w={{base:'calc(100% - 40px)',lg:'50%'}} bg='lightTema1.100' borderRadius={'0  5px 5px 0'}  _dark={{bg:'darkTema1.100'}} align={'center'} justify='center'  m={'0 auto'} > 
                        <Stack w='calc(100% - 40px)' align={'center'} justify='center' maxW={'440px'}  gap={'15px'} py={{base:'30px'}}>
                            <Image zIndex={1}   m={'0 auto'}  h='196px'  src={'/web/'+getWebsite().url+'/logo_menu_light.png'} display={{base:(colorMode=='light'?'none':'block'),lg:'none'}}   alt='login' opacity={1}  borderRadius={'5px'}/>
                            <Image zIndex={1}   m={'0 auto'}  h='196px'  src={'/web/'+getWebsite().url+'/logo_menu_dark.png'}  display={{base:(colorMode=='light'?'block':'none'),lg:'none'}}   alt='login' opacity={1}  borderRadius={'5px'}/>
                     
                             <SendForm  dados = {{modulo:'user',cmd:'register',form:{indicacao}}}  
                                schema={ 
                                    object({ 
                                        nome:       string().required('Razão Social é obrigatório!'),
                                        fantasia:   string().required('Nome Fantasia é obrigatório!'),
                                        email:      string().required('E-mail é obrigatório!'), 
                                        cnpj:       string().required('CNPJ é obrigatório!'), 
                                        telefone:   string().required('Telefone é obrigatório!'),
                                        senha1:     string().required('Senha é obrigatória'),
                                        senha2:     string().required('Confirmar senha é obrigatória')
                                    })
                                } 
                                callback={(result)=>{   
                                   
                                    setRedirect(<Navigate to={'/painel'} />);
                                }}
                                gap={'10px'}
                                > 
                                <HK4 pb={{base:'0px',md:'10px',lg:'20px'}} textAlign='center'>Cadastre sua conta</HK4>   
                                <InputK  type='text' label="Seu nome"   name="nome" placeholder="Nome completo"   focus={true} autoComplete={false}/> 
                                <Flex w={'full'} gap='20px'> 
                                    <InputK  type='email' label="Email" name="email" placeholder="Digite seu email"  w='50%'    autoComplete={false}/> 
                                    <InputK  type='phone' label="Telefone" name="telefone" placeholder="Telefone" w='50%'   autoComplete={false}/>
                                </Flex>
                                <Flex w={'full'} gap='20px'>
                                    <InputK type='password'  label="Senha" name="senha1"  placeholder="Sua senha"  autoComplete={false}/> 
                                    <InputK type='password'  label="Confirmar" name="senha2"  placeholder="Confirmar senha"   autoComplete={false}/> 
                                </Flex> 
                                <HStack w={'full'} align={'flex-end'} justify='flex-star' py='15px'> 
                                    {/* <Switch  _={{bg :'lightTema1.300'}} size='md' required={true}  />
                                    <TextK1>Li e aceito os </TextK1> 
                                    <LinkK href='/lost'>
                                        Termos e Condições de Uso
                                    </LinkK> */}
                                </HStack>
                                <ButtonKalyst w='full'>
                                    Registrar Conta
                                </ButtonKalyst>
                                <Flex w={'full'} align={'center'} justify='center' gap={'10px'} pt='15px'>
                                    <TextK1>Já tem uma conta?</TextK1> 
                                    <LinkK href='/login'>
                                        Fazer Login
                                    </LinkK>
                                </Flex>  
                            </SendForm>
                        </Stack> 
                    </Stack> 
                </Flex>
            </Flex>
            {redirect}
    </Flex>
}
import {  Flex, others, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

export function LinkK({children,href='',onClick=null, ...others}){
    const view = <Text   fontSize={{base:'12px',md:'14px',lg:'16px'}} lineHeight='20px'  borderBottom='1px solid' w='max-content' noOfLines={'1'} color={'textLight.100'} _hover={{color:'textLight.300'}} _dark={{color:'textDark.100'}}  {...others} >{children}</Text> 
    if(typeof onClick == 'function'){
        return (
            <Flex  onClick={onClick} cursor={'pointer'}  >
                {view}
            </Flex>      
        )
    }else{
        return (
            <NavLink  to={href}   >
                {view}
            </NavLink>      
        )
    }
   
}
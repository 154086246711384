import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, Button} from "@chakra-ui/react"; 
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import Typewriter from 'typewriter-effect';
import {   HK3, HK4, HK5 } from "../../../components/heading/HeadingKalyst";
import { SendForm } from "../../../components/form/SendForm";
import { object,string } from 'yup';
import { Helmet } from 'react-helmet-async';
import { TextK1 } from "../../../components/text/TextKalyst";
import { FaBeer } from "react-icons/fa"; 
import BoxDialog from "../../../components/modal/BoxDialog";
import { setSession } from "../../../components/session/SessionKalyst";
import { Navigate, Route } from "react-router-dom";
import { useState } from "react";
import { getWebsite } from "../../../theme/PageConfig";
import { LuMoonStar, LuSun } from "react-icons/lu";
export function PagePainelLogin({title}){
    const { colorMode ,toggleColorMode} = useColorMode();
    const [redirect,setRedirect] = useState(null);  
    
    return <Flex zIndex={2} w='100vw' bg={'bgLoginLight.300'}  _dark={{bg:'bgLoginDark.100'}} > 
            <Flex zIndex={3} w='full' align={'center'} justify='center'   minH={'100vh'}   margin = '0 auto' >
                <Flex w={{base:'100vw'}} maxW = 'calc(100%)' minH={{base:'auto',md:'100%'}}    borderRadius={'5px'}>
                    <Stack display={{base:'none',lg:'flex'}} w={{base:'50%'}} p='0' position='relative' align={'flex-end'} justify='center' >
                        <Image zIndex={1} position={'absolute'}  bottom='10px' left='0px'  w={{base:'200px',lg:'300px'}} src={'/web/'+getWebsite().url+'/avatar.png'}   alt='avatar'    borderRadius={'5px'}/>
                        <Stack zIndex={2} w='100%' pt='120px' align={'center'} justify='flex-start' maxW={'440px'} h='100%'    gap={'15px'}>
                            <Image zIndex={1}   h='180px'  src={'/web/'+getWebsite().url+'/banner.png'} alt='login'    borderRadius={'5px'}/> 
                            <HK5  fontWeight={'bold'} color='textLight.400' _dark={{color:'textDark.200'}}  noOfLines={3} textAlign='right'>COMUNIDADE</HK5>
                            <Flex>
                                <HK3  fontWeight={'bold'} color='textLight.400' _dark={{color:'textDark.300'}}> 
                                    <Typewriter 
                                        options={{
                                            strings: ['RENDA', 'GANHE', 'PROSPERE'],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                    />  
                                </HK3>  
                                <HK3 color='white' fontWeight={'bold'}> 
                                  MAIS!
                                </HK3> 
                            </Flex>
                            
                        </Stack>
                    </Stack>
                    <Stack w={{base:'calc(100% - 40px)',lg:'50%'}}   borderRadius={'0  5px 5px 0'}   bg='lightTema1.100' _dark={{bg:'darkTema1.100'}}   align={'center'} justify='center'  m={'0 auto'} > 
                        <Stack w='calc(100% - 40px)'align={'center'} justify='center' maxW={'440px'}  gap={'15px'}  py={{base:'30px'}}  pb='70px'  >
                            <SendForm  dados = {{modulo:'user',cmd:'login'}}
                                initial = {
                                    {
                                        login    :   '',
                                        senha    :   ''
                                    }
                                } 
                                schema={
                                    object({ 
                                        login:      string().required('E-mail es obligatorio!'),
                                        senha:   string().required('Senha é obrigatória')
                                    })
                                } 
                                callback={(result)=>{ 
                                    setSession({token:result.hash.token,dados:result.hash.dados}); 
                                    setRedirect(<Navigate to={'/dashboard'} />);
                                }}
                                gap={'15px'}
                                > 
                                <Image zIndex={1} w='auto' m={'0 auto'}  h='160px'  src={'/web/'+getWebsite().url+'/logo_menu_light.png'} display={{base:(colorMode=='light'?'none':'block'),lg:'none'}}   alt='login' opacity={1}  borderRadius={'5px'}/>
                                <Image zIndex={1} w='auto' m={'0 auto'}  h='160px'  src={'/web/'+getWebsite().url+'/logo_menu_dark.png'}  display={{base:(colorMode=='light'?'block':'none'),lg:'none'}}   alt='login' opacity={1}  borderRadius={'5px'}/>
                           
                        
                                <HK4 pb={{base:'0px',md:'10px',lg:'20px'}} textAlign='center'>Acessar sua Conta</HK4> 
                                <InputK  type='email' label="Email" name="login" placeholder="Digite seu email"    autoComplete={true}/>
                                <InputK type='password'  label="Senha" name="senha"  placeholder="Digite sua senha"    autoComplete={true}/> 
                                <Stack w={'full'} align={'flex-end'} justify='flex-end'>
                                    <LinkK href='/lost'>
                                        Esqueci a senha
                                    </LinkK>
                                </Stack>
                                <ButtonKalyst w='full'>
                                    Fazer Login
                                </ButtonKalyst>
                                <Flex w={'full'} align={'center'} justify='center' gap={'10px'}>
                                    <TextK1>Ainda não tem cadastro?</TextK1> 
                                    <LinkK href='register'>
                                        Cadastre-se
                                    </LinkK>
                                </Flex>  
                            </SendForm>
                            <Button position={'absolute'} bottom='20px' onClick={()=>{
                                toggleColorMode()
                            }} leftIcon={colorMode==='light'?<LuSun/>:<LuMoonStar/>  } fontSize='20px' variant={'ghost'} w='auto'  bg={'bgMenuLight.100'} color='textLight.100' _dark={{bg: 'bgMenuDark.100',color:'textDark.100'}}>
                                <Text fontSize='16px'  _dark={{bg: 'bgMenuDark.100',color:'textDark.100'}} fontWeight='500'>{colorMode==='light'?'Light':'Dark'} Mode</Text>
                            </Button>
                        </Stack> 
                    </Stack> 
                </Flex>
            </Flex>
            {redirect}
    </Flex>
}
import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, HStack, PinInput, PinInputField, Button} from "@chakra-ui/react"; 
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import Typewriter from 'typewriter-effect';
import {   HK3, HK4, HK5 } from "../../../components/heading/HeadingKalyst";
import { SendForm } from "../../../components/form/SendForm";
import { number, object,string } from 'yup';
import { Helmet } from 'react-helmet-async';
import { TextK1 } from "../../../components/text/TextKalyst";
import { FaBeer } from "react-icons/fa"; 
import BoxDialog from "../../../components/modal/BoxDialog";
import { setSession } from "../../../components/session/SessionKalyst";
import { Navigate, Route } from "react-router-dom";
import { useState } from "react";
import { getWebsite } from "../../../theme/PageConfig";
import { LuMoonStar, LuSun } from "react-icons/lu";
export function PagePainelLost({title}){
    const { colorMode ,toggleColorMode} = useColorMode();
    const [email,setEmail] = useState('');
    const [code,setCode] = useState('828091');
    const [cmd,setCmd] = useState('');
    
    const [redirect,setRedirect] = useState(null); 
    let view = null;
    switch (cmd) {
        case 'senha':
            view = (
                <SendForm  dados = {{modulo:'user',cmd:'lost',form:{email,code}}}
                    initial = {
                        {
                            senha1       :   '',
                            senha2       :   '' 
                        }
                    } 
                    schema={
                        object({ 
                            senha1:      string().required('Nova Senha es obligatorio!'),
                            senha2:      string().required('Confirmar Senha es obligatorio!') 
                        })
                    } 
                    callback={(result)=>{  
                        setRedirect(<Navigate to={'/login'} />); 
                    }}
                    gap={'15px'}
                    > 
                          
                    <HK4 pb={{base:'0px',md:'10px',lg:'20px'}} textAlign='center'>Gerar nova Senha</HK4>   
                    <InputK type='password'  label="Nova Senha"         name="senha1"  placeholder="Digite sua nova senha"  autoComplete={false}/> 
                    <InputK type='password'  label="Confirmar Senha"    name="senha2"  placeholder="Confirmar sua nova senha"  autoComplete={false}/> 
                    <ButtonKalyst w='full'>
                        Salvar Nova Senha
                    </ButtonKalyst>
                    <Flex w={'full'} align={'center'} justify='center' gap={'10px'}>
                        <TextK1>Lembrou a senha?</TextK1> 
                        <LinkK href='login'>
                        Fazer Login
                        </LinkK>
                    </Flex>   
                </SendForm>
            )
        break;
        case 'new':case 'reload':
            view = (
                <SendForm  dados = {{modulo:'user',cmd:'lost',form:{email,code}}}  
                    callback={(result,json)=>{  
                        try { 
                            setEmail(json.email);
                            setCode(json.code);
                            setCmd(result.hash.cmd)
                        } catch (error) {
                            
                        } 
                    }}
                    gap={'15px'}
                    > 
                    <HK4 pb={{base:'0px',md:'5px',lg:'10px'}} textAlign='center'>Recuperar sua Conta</HK4>  
                    <TextK1 noOfLines="auto">Foi enviado uma chave de segurança para seu e-mail <Text as='span' fontWeight={'bold'} color='lightTema1.300'>{email}</Text>, Verifique sua caixa de entrada ou span, em seguida digite o pin abaixo:</TextK1> 
                    <HStack w={'full'} pt='20px'  align={'center'} justify='center'> 
                        <PinInput manageFocus={true} type='alphanumeric'focus onChange={(v)=>{
                            setCode(v); 
                        }}   size={'lg'}>
                            <PinInputField bg='lightTema1.200' color='lightTema1.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}} />
                            <PinInputField bg='lightTema1.200' color='lightTema1.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                            <PinInputField bg='lightTema1.200' color='lightTema1.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                            <PinInputField bg='lightTema1.200' color='lightTema1.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                            <PinInputField bg='lightTema1.200' color='lightTema1.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                            <PinInputField bg='lightTema1.200' color='lightTema1.300' border='2px solid' fontSize={'24px'}  _dark={{bg:'darkTema1.100'}}/>
                        </PinInput>
                    </HStack> 
                    <Flex w={'full'} pb='20px' align={'center'} justify='center' gap={'10px'}>
                        <LinkK href='login'>
                            Reenviar e-mail
                        </LinkK>
                    </Flex>
                    
                    <ButtonKalyst w='full'>
                        Validar PIN
                    </ButtonKalyst>
                    <Flex w={'full'} align={'center'} justify='center' gap={'10px'}>
                        <TextK1>Lembrou a senha?</TextK1> 
                        <LinkK href='login'>
                        Fazer Login
                        </LinkK>
                    </Flex>   
                </SendForm>
            )
        break; 
        default:
            view = (
                <SendForm  dados = {{modulo:'user',cmd:'lost'}}
                    initial = {
                        {
                            email       :   '' 
                        }
                    } 
                    schema={
                        object({ 
                            email:      string().required('E-mail es obligatorio!') 
                        })
                    } 
                    callback={(result,json)=>{  
                        try { 
                            setEmail(json.email);
                            setCmd(result.hash.cmd)
                        } catch (error) {
                            
                        } 
                    }}
                    gap={'15px'}
                    > 
                    <HK4 pb={{base:'0px',md:'10px',lg:'20px'}} textAlign='center'>Recuperar sua Conta</HK4>   
            
                    <InputK  type='email' label="Email" name="email" placeholder="Digite seu email"   focus={true} autoComplete={false}/>
                    
                    <ButtonKalyst w='full'>
                        Recuperar Conta
                    </ButtonKalyst>
                    <Flex w={'full'} align={'center'} justify='center' gap={'10px'}>
                        <TextK1>Lembrou a senha?</TextK1> 
                        <LinkK href='login'>
                        Fazer Login
                        </LinkK>
                    </Flex>   
                </SendForm>
            )
        break;
    }
    return (
        <Flex zIndex={2} w='100vw'  bg={'bgLoginLight.300'}  _dark={{bg:'bgLoginDark.100'}} > 
             <Flex zIndex={3} w='full' align={'center'} justify='center'   minH={'100vh'}   margin = '0 auto' >
                <Flex w={{base:'100vw'}} maxW = 'calc(100%)' minH={{base:'auto',md:'100%'}}    borderRadius={'5px'}>
                    <Stack display={{base:'none',lg:'flex'}} w={{base:'50%'}} p='0' position='relative' align={'flex-end'} justify='center' >
                        <Image zIndex={1} position={'absolute'}  bottom='10px' left='0px'  w={{base:'200px',lg:'300px'}} src={'/web/'+getWebsite().url+'/avatar.png'}   alt='avatar'    borderRadius={'5px'}/>
                        <Stack zIndex={2} w='100%' pt='120px' align={'center'} justify='flex-start' maxW={'440px'} h='100%'    gap={'15px'}>
                            <Image zIndex={1}   h='180px'  src={'/web/'+getWebsite().url+'/banner.png'} alt='login'    borderRadius={'5px'}/> 
                            <HK5  fontWeight={'bold'} color='textLight.400' _dark={{color:'textDark.200'}}  noOfLines={3} textAlign='right'>COMUNIDADE</HK5>
                            <Flex>
                                <HK3  fontWeight={'bold'} color='textLight.400' _dark={{color:'textDark.300'}}> 
                                    <Typewriter 
                                        options={{
                                            strings: ['RENDA', 'GANHE', 'PROSPERE'],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                    />  
                                </HK3>  
                                <HK3 color='white' fontWeight={'bold'}> 
                                  MAIS!
                                </HK3> 
                            </Flex>
                            
                        </Stack>
                    </Stack>
                    <Stack w={{base:'calc(100% - 40px)',lg:'50%'}}   borderRadius={'0  5px 5px 0'}   bg='lightTema1.100' _dark={{bg:'darkTema1.100'}}   align={'center'} justify='center'  m={'0 auto'} > 
                        <Stack w='calc(100% - 40px)'align={'center'} justify='center' maxW={'440px'}  gap={'15px'}  py={{base:'30px'}}  pb='70px'  >
                            <Image zIndex={1}   m={'0 auto'}  h='196px'  src={'/web/'+getWebsite().url+'/logo_menu_light.png'} display={{base:(colorMode=='light'?'none':'block'),lg:'none'}}   alt='login' opacity={1}  borderRadius={'5px'}/>
                            <Image zIndex={1}   m={'0 auto'}  h='196px'  src={'/web/'+getWebsite().url+'/logo_menu_dark.png'}  display={{base:(colorMode=='light'?'block':'none'),lg:'none'}}   alt='login' opacity={1}  borderRadius={'5px'}/>
                     
                            {view}
                            <Button position={'absolute'} bottom='20px' onClick={()=>{
                                toggleColorMode()
                            }} leftIcon={colorMode==='light'?<LuSun/>:<LuMoonStar/>  } fontSize='20px' variant={'ghost'} w='auto'  bg={'bgMenuLight.100'} color='textLight.100' _dark={{bg: 'bgMenuDark.100',color:'textDark.100'}}>
                                <Text fontSize='16px'  _dark={{bg: 'bgMenuDark.100',color:'textDark.100'}} fontWeight='500'>{colorMode==='light'?'Light':'Dark'} Mode</Text>
                            </Button>
                        </Stack> 
                    </Stack> 
                </Flex>
            </Flex>
            {redirect}
        </Flex>
    )
}
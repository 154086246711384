import { Flex, Stack,Icon ,Image, Accordion, AccordionItem, AccordionButton, AccordionIcon, AccordionPanel, Skeleton, Box, Center, Button, useColorMode, Text, Menu, MenuButton, MenuList, MenuItem, IconButton, Drawer, useDisclosure, DrawerBody, DrawerContent, DrawerOverlay, DrawerHeader, DrawerCloseButton, DrawerFooter, Spacer, InputGroup, InputLeftElement, Input, InputRightElement, Kbd} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { LuAlertTriangle, LuBarChart2, LuChevronRight, LuLogOut, LuMail, LuMenu, LuMoon, LuMoonStar, LuPlusCircle, LuSearch, LuSettings, LuStore, LuSun, LuSunDim, LuSunMedium, LuUser2 } from "react-icons/lu";
import { Navigate, NavLink } from "react-router-dom";
import BoxDialog from "../../components/modal/BoxDialog";
import { sendFormSocket, setSession,fase, user } from "../../components/session/SessionKalyst";
import { getMenuRoutes } from "./PagesPainel";
import { getWebsite } from "../../theme/PageConfig";
 
function BuscaFast(){
    return(
        <>
            <InputGroup bg={'transparent'} borderRadius={'lg'}  w='full'   color='textLight.100'  outline={'red'}  _dark ={{bg:'bgMenuDark.200',color:'textDark.100'}} >
                <InputLeftElement pointerEvents='none'  color='textLight.100' _dark ={{color:'textDark.100'}} fontSize='1.6em'>
                    <Icon as={LuSearch}/>
                </InputLeftElement>
                <Input borderRadius={'lg'} pr={'5em'}    border='0' bg={'lightTema1.400'} color='textLight.100' _dark ={{bg:'bgMenuDark.200',color:'textDark.100'}}/>
                <InputRightElement  w={'5em'}>
                    <Text   color='textLight.100' _dark ={{bg:'bgMenuDark.200',color:'textDark.100'}} fontSize='12px' fontWeight={'bold'}>  CTRL       + K </Text>
                </InputRightElement>
            </InputGroup>
        </>
    )
}
function MenuResponsivo({children,menuCMD}){
    const { isOpen, onOpen, onClose } = menuCMD;
    const { colorMode, toggleColorMode } = useColorMode() 
    return <> 
        <Drawer zIndex={'10'}   placement={'left'} onClose={onClose} isOpen={isOpen}>
            <DrawerOverlay />
            <DrawerContent  display={{base:'block',md:'none'}}  bg={'bgMenuLight.100'} _dark={{bg: 'bgMenuDark.100'}}>
                <DrawerCloseButton />
                <DrawerHeader  h={'52px'}> 
                    <Flex mt={'-10px'} w='100%' align={'center'} justify='center'>
                        <Image display ={colorMode==='light'?'flex':'none' } src={'/web/'+getWebsite().url+'/logo_menu_dark.png'} h={'96px'}   />
                        <Image display ={colorMode==='light'?'none':'flex' }  src={'/web/'+getWebsite().url+'/logo_menu_light.png'} h={'96px'}    />  
                    </Flex> 
                </DrawerHeader> 
                <DrawerBody  display={{base:'block',md:'none'}} h='calc(100% - 124px)' >
                {children}
                </DrawerBody>
                <DrawerFooter>
                    <Button onClick={()=>{
                        toggleColorMode()
                    }} leftIcon={colorMode==='light'?<LuSun/>:<LuMoonStar/>  } fontSize='20px' variant={'ghost'} w='full'  bg={'bgMenuLight.100'} color='textLight.100' _dark={{bg: 'bgMenuDark.100',color:'textDark.100'}}>
                        <Text fontSize='16px'  _dark={{bg: 'bgMenuDark.100',color:'textDark.100'}} fontWeight='500'>{colorMode==='light'?'Light':'Dark'} Mode</Text>
                    </Button>
                </DrawerFooter>
            </DrawerContent>  
        </Drawer>  
        <Stack zIndex={'30'} position={'fixed'}  display={{base:'none',md:'flex',}} pt={{base:'0px'}} px='16px' align='center' justify={'flex-start'} left='0px' top={'0px'} w={'280px'} h='100vh' bg={'bgMenuLight.100'} _dark={{bg: 'bgMenuDark.100'}} >
            <Flex h={'40px'}  >
                <Image display ={colorMode==='light'?'flex':'none' }  src={'/web/'+getWebsite().url+'/logo_menu_dark.png'}  h={'90px'}  />
                <Image display ={colorMode==='light'?'none':'flex' }  src={'/web/'+getWebsite().url+'/logo_menu_light.png'} h={'90px'} />
            </Flex> 
            {children}
            <Button  mb={'20px'} onClick={()=>{
                toggleColorMode()
            }} leftIcon={colorMode==='light'?<LuSun/>:<LuMoonStar/>  } fontSize='20px' variant={'ghost'} w='full'  bg={'bgMenuLight.100'} color='textLight.100' _dark={{bg: 'bgMenuDark.100',color:'textDark.100'}}>
                <Text fontSize='16px'  _dark={{bg: 'bgMenuDark.100',color:'textDark.100'}} fontWeight='500'>{colorMode==='light'?'Light':'Dark'} Mode</Text>
            </Button>
        </Stack> 
    </>
}
export let LojaSelect = null;
export default function MenuPainel({page}){ 
    const menuCMD = useDisclosure() 
    let url = '';
    page.forEach((p,i)=>{
        url+= "/"+p;
    });
    const [alert,setAlert] = useState({}); 
    const { colorMode } = useColorMode() 
    const [open,setOpen] = useState(null);
    const menus =getMenuRoutes({logado:true}); 
    const [reload,setReload] = useState(null)
    let viewStores = []; 
    const [dados,setDados]  = useState(null);
    const [faseI,setFaseI]  = useState(fase);
    function BuscarDados(reload){
        sendFormSocket({campo:'pages', modulo:'store',cmd:'menu',form:{reload},callback:(result)=>{   
            setDados(typeof result.lojas == 'undefined'?null:result.lojas)
            setReload({});
        }});
    } 
   
    useEffect(()=>{
        BuscarDados(true);
        var interV =setInterval(()=>{
            BuscarDados(false);
            if(fase!= faseI){
                setFaseI(fase);
            }
        },500);
        return  ()=>{
            clearInterval(interV);
        }
    },[]);  
    
    var lojaId  = null;
    var lojaNome = 'Selecione';
    var loaded  = true; 
    // 
    return  (
        <Flex zIndex={20} position={'fixed'} w={'100%'} h={{base:'60px'}} bg='rgba(255,255,255,0.7)' _dark={{bg:'rgba(40,40,40,0.8)'}} >
            
            <MenuResponsivo menuCMD={menuCMD}>
                <Stack w={'full'} h='calc(100% - 20px)' mt='20px'   flex='1' overflowY={'auto'} overflowX='hidden'> 
                    <Accordion  allowToggle={true}  defaultIndex={open}   >
                        {menus.map((menu,keyM)=>{
                            let selection = false;
                            if(typeof menu.sub == 'undefined'){
                                if(url == menu.link.toUpperCase()){
                                    if(open== null){
                                        setOpen(keyM);
                                    } 
                                    selection = true;
                                }
                            }else{
                                menu.sub.forEach((sub)=>{
                                    if(url == sub.link.toUpperCase()){
                                        if(open== null){
                                            setOpen(keyM);
                                        } 
                                        selection = true;
                                    }
                                })
                            } 
                            if(!menu.hidden){
                                return null;
                            }else{
                                const bt = (
                                    <AccordionButton   px={'10px'} gap='10px'  bg={selection?'bgMenuLight.200':'bgMenuLight.200'}  borderLeft='3px solid' borderRadius={'3px'}  borderColor={selection?'bgMenuLight.300':'gray.500'} _hover={{borderColor:'bgMenuLight.400'}}  _dark={{bg:  selection?'bgMenuDark.200':'transparent' }}> 
                                        {menu.Icon!==null?<Icon fontSize={'22px'} fontWeight={'bold'} as={menu.icon} color={'textLight.100'} _dark={{color:'textDark.100'}}  />:null}
                                        <Box as="span" flex='1' color={'textLight.100'} _dark={{color:'textDark.100'}}   textAlign='left' fontSize={'16px'} >
                                            {menu.title}
                                        </Box>
                                        {typeof menu.sub!=='undefined'?<AccordionIcon />:null} 
                                    </AccordionButton>
                                )    
                                return (
                                    <AccordionItem key={'menu'+keyM} border={'0'}    p={'0px'} lineHeight='32px' mt={'3px'} >
                                        <h2 >
                                            {typeof menu.sub==='undefined'?<NavLink to={menu.link} onClick={()=>{   menuCMD.onClose();  }} >{bt}</NavLink>:bt} 
                                        </h2> 
                                        {typeof menu.sub!=='undefined'?
                                            <AccordionPanel  p={'0px'} mt='2px' >
                                                <Stack borderRadius={'0'} overflow={'hidden'} gap={'1px'}> 
                                                    {
                                                        menu.sub.map((sub,keyS)=>{
                                                            if(!sub.hidden){
                                                                return null;
                                                            }else{
                                                                let mark =false;
                                                                if(url == sub.link.toUpperCase()){
                                                                    if(open== null){
                                                                        setOpen(keyM);
                                                                    } 
                                                                    mark = true;
                                                                }
                                                                return   (
                                                                    <NavLink key={'menu'+keyM+'sub'+keyS} to={sub.link}  w='full' onClick={()=>{
                                                                        setOpen(false);
                                                                    }} >
                                                                        <Button h={'24px'} textShadow={'none'}  gap={'10px'}   w='full'   mb='2px' opacity={mark?'1':'0.7'} bg={'transparent'} _dark={{bg:'transparent'}} _hover={{opacity:'1'}} _selection={{}}  borderRadius={0}>
                                                                            {sub.Icon!==null?<Icon fontSize={'16px'} fontWeight={'bold'} as={LuChevronRight } color={mark?'textDark.300':'textLight.100'} _dark={{color:mark?'textDark.300':'textDark.100'}}  />:null}
                                                                            {/* <Icon as={getEsporteIcon(e.id)} w={'18px'} h={'18px'} color={'tema.cor002'} borderRadius={'sm'}    /> */}
                                                                            <Box as="span"   fontWeight={'500'} flex='1'  color={mark?'textDark.300':'textLight.100'} _dark={{color:mark?'textDark.300':'textDark.100'}}   textAlign='left' fontSize={'12px'} >
                                                                                {sub.title}
                                                                            </Box>
                                                                        </Button>
                                                                    </NavLink>
                                                                ) 
                                                            }  
                                                        })
                                                    } 
                                                </Stack> 
                                            </AccordionPanel>
                                        :null}
                                    </AccordionItem>
                                )
                            }
                           
                        })} 
                    </Accordion> 
                </Stack> 
            </MenuResponsivo> 
            <Flex  flexDirection={{base:'row'}}  w='100%' pl={{base:'0px',md:'300px'}} align='center' justify={'flex-end'}> 
                <Flex  w='100%'  h={{base:'80px',md:'auto'}}  align='center' justify={'flex-end'} px={{base:'16px',md:'32px'}}>
                    <Flex display={ {base:'flex',md:'none'}} align={'center'} justify='center'>
                        <Button  variant={'ghost'} onClick={menuCMD.onOpen} fontSize='28px' p={0}>
                            <Icon as={LuMenu}/>
                        </Button> 
                        {/* <Image display ={colorMode==='light'?'flex':'none' } src={'/web/'+getWebsite().url+'/logo_menu_dark.png'} h={'24px'} mt='4px'  />
                        <Image display ={colorMode==='light'?'none':'flex' }  src={'/web/'+getWebsite().url+'/logo_menu_light.png'} h={'24px'} mt='4px'   />  */}
                    </Flex>
                    <Spacer/> 
                    <Menu> 
                    <MenuButton   variant='solid'>
                        <Flex  borderRadius={'6px'}     maxW={{base:'150px',md:'160px',lg:'200px'}}   px={'10px'} pr='40px' align={'center'}  h='40px'  bg={'lightTema1.400'} color='textLight.100' _dark ={{bg:'bgMenuDark.200',color:'textDark.100'}} gap={'10px'} >
                            <Icon as={LuSettings}   fontSize={{base:'18px',md:'22px',lg:'26px'}}  color='textLight.100' _dark ={{color:'textDark.200'}}/>
                            <Stack  w='full'     gap='0px' > 
                                <Text textAlign={'left'} position={'relative'}  w={'100%'} fontSize='12px' lineHeight={'14px'} noOfLines={1} color='textLight.300' _dark ={{color:'textDark.300'}}>{user.nome}</Text>
                                <Text textAlign={'left'} as={'span'} w={'100%'}  fontSize='10px' noOfLines={'1'} lineHeight='12px' color='textLight.100' _dark ={{color:'textDark.100'}}>{user.email}</Text>
                            </Stack>
                        </Flex> 
                    </MenuButton>
                    <MenuList bg='bgMenuLight.100' color={'textLight.200'} _dark={{bg:'bgMenuDark.100',color:'textDark.200'}}>
                        {/* <NavLink to='/document'>
                            <MenuItem icon={<LuUser2  fontSize={'22px'} />} bg='bgMenuLight.100' _dark={{bg:'bgMenuDark.100'}}  >
                                Documentos
                            </MenuItem>
                        </NavLink>  */}
                        <MenuItem onClick={()=>{
                            setAlert({load:true});
                            setTimeout(()=>{  
                                setAlert({
                                    fechar:true,
                                    icon   : <Icon as={LuAlertTriangle} h={{base:'50px'}} w={{base:'50px'}} color='orange.300' />, 
                                    titulo:'Atenção',
                                    size:'md',
                                    mensagem:'Deseja realmente sair de sua conta?', 
                                    botao:[
                                        {
                                            nome:'SIM', 
                                            cmd:()=>{
                                                setSession({token:'',dados:'{}'});
                                                setAlert({exit:true})
                                            }
                                        },
                                        {
                                            nome:'NÃO',
                                            variant:'ghost',
                                        }
                                    ]
                                });
                            });
                        }} icon={<LuLogOut fontSize={'22px'}/>} bg='bgMenuLight.100'  _dark={{bg:'bgMenuDark.100'}} >
                            Sair
                        </MenuItem> 
                    </MenuList>
                    </Menu> 
                </Flex>
            </Flex>
            <BoxDialog param={alert}  />
        </Flex> 
    )
        
}
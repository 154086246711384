import { FaUsers } from "react-icons/fa";
import { LuArrowLeftRight, LuBarChart2, LuBoxes, LuDollarSign, LuFileText, LuLink, LuLink2, LuListChecks, LuPackage, LuPanelLeft, LuPercent, LuStore, LuUploadCloud, LuUser, LuUser2, LuUserCog2, LuUserPlus, LuUsers, LuUsers2, LuWrench } from "react-icons/lu";
import { MdOutlineBusinessCenter } from "react-icons/md";

import { PagePainelLogin } from "../../pages/painel/auth/PageLogin";
import { PagePainelLost } from "../../pages/painel/auth/PageLost"; 
import { PagePainelRegister } from "../../pages/painel/auth/PageRegister";
import { PagePainelDashboard } from "../../pages/painel/dashboard/PageDashboard";
import { PagePainelDocument } from "../../pages/painel/document/PageDocument";

import { PagePainelClient } from "../../pages/painel/client/PageClient";
import { PagePainelProducts } from "../../pages/painel/products/PageProducts";
import { PagePainelReports } from "../../pages/painel/reports/PageReports";
import { PagePainelAbandoned } from "../../pages/painel/abandoned/PageAbandoned";
import { fase, user } from "../../components/session/SessionKalyst";
import { PagePainelStore } from "../../pages/painel/store/PageStore";
import { PageAPI } from "../../pages/painel/api/PageAPI";
import { PageWebhooks } from "../../pages/painel/webhooks/PageWebhooks";
import { PagePainelFrete } from "../../pages/painel/frete/PageFrete";
import { PagePainelLink } from "../../pages/painel/link/PageLink";
import { PagePainelTransaction } from "../../pages/painel/transaction/PageTransaction";
import { PagePainelWithdraw } from "../../pages/painel/withdraw/PageWithdraw";

import { GrTransaction } from "react-icons/gr";
import { FaCartFlatbed } from "react-icons/fa6";
import { PiNetworkThin } from "react-icons/pi";
import { PageAffiliates } from "../../pages/painel/affiliates/PageAffiliates";
let painelRoutes = null;
export function getPainelRoutes(){
    return painelRoutes;
};
export function getMenuRoutes(login=false,acessos={}){
    let sendM= [];
    let subs = {}; 
    painelRoutes.forEach(rota => {
        var add = true;
        if(rota.url=='/affiliate'){
            add=false;
            try {
                if(user.person.afiliados){
                    add=true;
                } 
            } catch (error) {
                
            } 
        }
        if(add){
            if(login ){    
                if(rota.login  && (rota.fase == null|| rota.fase == fase)){ 
                    if(rota.sub == null){
                        sendM.push({title:rota.title,icon:rota.icon,link:rota.url,hidden:rota.menu,tema:rota.tema});
                    }else{
                        if(typeof subs[rota.sub.title] == 'undefined'){
                            subs[rota.sub.title] = {title:rota.sub.title,icon:rota.sub.icon,sub:[],hidden:false};
                            painelRoutes.forEach(rotaS => {
                                if(rotaS.sub!==null && rota.sub.title == rotaS.sub.title){
                                    if(rotaS.menu){
                                        subs[rota.sub.title].hidden=true;
                                    }
                                    subs[rota.sub.title].sub.push({title:rotaS.title,icon:rotaS.icon,link:rotaS.url,hidden:rotaS.menu,tema:rotaS.tema})
                                }
                            })
                            sendM.push(subs[rota.sub.title]);
                        } 
                    } 
                }
            }else{
                if(!rota.login && rota.menu){
                    sendM.push(rota);
                }
            }
        }
     
    });
    return sendM;
};
const newRoutePainel = function({title='',icon=null,description='',url = '',tema=false,layout='painel',login=false,page, session='sessionPainel',redirect='',menu=false, sub=null,acesso=0,fase:faseI=null}){
    painelRoutes.push({
        title,
        icon,
        description,
        layout,
        login,
        page,
        url,
        session,
        redirect,
        menu,
        sub,
        fase:faseI,
        tema,
        acesso
    })
}
const controller = '';
export function loadPainelRoutes(){
    if(painelRoutes == null){
        painelRoutes = [];
         
        newRoutePainel({ title       :   "Login",       url:controller+"/",             page        : PagePainelLogin,     tema:false,   login       : false, redirect    : '/dashboard' });
        newRoutePainel({ title       :   "Login",       url:controller+"/login",       page        : PagePainelLogin,     tema:false,    login       : false, redirect    : '/dashboard' });
        
        newRoutePainel({ title       :   "Recuperação de conta",  url:controller+"/lost",        page        : PagePainelLost,          login: false, redirect    : '/dashboard' });
        newRoutePainel({ title       :   "Registro de conta",     url:controller+"/register",    page        : PagePainelRegister,      login: false, redirect    : '/dashboard' });
        
        
        newRoutePainel({ title       :   "Análise",         icon:LuBarChart2,       url:controller+"/dashboard",    page:   PagePainelDashboard,     tema:true,  login : true , menu:true  });
        newRoutePainel({ title       :   "Documentos",      icon:LuUploadCloud,     url:controller+"/document",     page:   PagePainelDocument,      tema:true,  fase: 1,  login : true , menu:true });
       
        // newRoutePainel({ title       :   "Lojas",           icon:LuStore,           url:controller+"/store",        page:   PagePainelStore,         tema:true,  fase: 2,  login : true , menu:true });
        // newRoutePainel({ title       :   "Lojas",           icon:LuStore,           url:controller+"/store",        page:   PagePainelStore,         tema:true,  fase: 3,  login : true , menu:true }); 
        // newRoutePainel({ title       :   "Transações",                              url:controller+"/transaction",  page:   PagePainelTransaction,   tema:true,  fase: 3,  login : true , menu:true,    sub:{ title :   "Gestão",  icon: GrTransaction}});
        // newRoutePainel({ title       :   "Saques",                                  url:controller+"/withdraw",     page:   PagePainelWithdraw,      tema:true,  fase: 3,  login : true , menu:true,    sub:{ title :   "Gestão"} });

        // newRoutePainel({ title       :   "Pedidos",         icon: LuPackage,        url:controller+"/reports",      page:   PagePainelReports,       tema:true,  fase: 3,  login : true , menu:true }); 
        // newRoutePainel({ title       :   "Clientes",        icon: LuUsers,          url:controller+"/clients",      page:   PagePainelClient,        tema:true,  fase: 3,  login : true , menu:true });
        // newRoutePainel({ title       :   "Produtos",        icon: FaCartFlatbed,    url:controller+"/products",     page:   PagePainelProducts,      tema:true,  fase: 3,  login : true , menu:true }); 
        

        // newRoutePainel({ title       :   "Customizar",              url:controller+"/custom",       page:   PagePainelFrete,        tema:true,  fase: 3,  login : true , menu:true ,    sub:{ title       :   "Checkout",  icon: LuPanelLeft }});
        // newRoutePainel({ title       :   "Links",                   url:controller+"/links",        page    : PagePainelLink,       tema:true,  fase: 3,  login : true , menu:true ,    sub:{ title       :   "Checkout"}});
        // newRoutePainel({ title       :   "Fretes",                  url:controller+"/frete",        page:   PagePainelFrete,        tema:true,  fase: 3,  login : true , menu:true ,    sub:{ title       :   "Checkout"}});
        // newRoutePainel({ title       :   "UpSell",                  url:controller+"/upsell",       page:   PagePainelReports,      tema:true,  fase: 3,  login : true , menu:true ,    sub:{ title       :   "Checkout"}});
        // newRoutePainel({ title       :   "OrderBump",               url:controller+"/orerbamp",     page:   PagePainelReports,      tema:true,  fase: 3,  login : true , menu:true ,    sub:{ title       :   "Checkout"}});
        // newRoutePainel({ title       :   "Carrinho Abandonado",     url:controller+"/abandoned",    page:   PagePainelAbandoned,    tema:true,  fase: 3,  login : true , menu:true ,    sub:{ title       :   "Checkout"}});
        // newRoutePainel({ title       :   "Clientes",                url:controller+"/clients",      page:   PagePainelClient,       tema:true,  fase: 3,  login : true , menu:true ,    sub:{ title       :   "Checkout"}});
        // newRoutePainel({ title       :   "Produtos",                url:controller+"/products",     page:   PagePainelProducts,     tema:true,  fase: 3,  login : true , menu:true ,    sub:{ title       :   "Checkout"}}); 
         
        
         
        

        // newRoutePainel({ title       :   "Cupom",                   url:controller+"/cupom",        page:   PagePainelDashboard,    tema:true,  fase: 3,  login : true , menu:true  ,     sub:{ title       :   "Marketing"}});
        // newRoutePainel({ title       :   "Pixel",                   url:controller+"/pixel",        page:   PagePainelDashboard,    tema:true,  fase: 3,  login : true , menu:true  ,     sub:{ title       :   "Marketing"}});
       
        // newRoutePainel({ title       :   "Customizar Checkout",     url:controller+"/checkout",     page:   PagePainelDashboard,    tema:true,  fase: 3,  login : true , menu:true  ,     sub:{ title       :   "Configurações",  icon: LuWrench}});
        
       
        // newRoutePainel({ title       :   "Shopify",                 url:controller+"/shopify",      page:   PagePainelDashboard,    tema:true,  fase: 3,  login : true , menu:true  ,     sub:{ title       :   "Integrações",  icon: LuBoxes}});
        // newRoutePainel({ title       :   "Reportana",               url:controller+"/reportana",    page:   PagePainelDashboard,    tema:true,  fase: 3,  login : true , menu:true  ,     sub:{ title       :   "Integrações"}});
        // newRoutePainel({ title       :   "Afiliados",       icon: PiNetworkThin,          url:controller+"/affiliate",    page:   PageAffiliates,        tema:true,  fase: 3,  login : true , menu:true });
        // newRoutePainel({ title       :   "Credenciais de API",      url:controller+"/api",          page:   PageAPI,    tema:true,  fase: 3,  login : true , menu:true  ,     sub:{ title       :   "Integrações",icon: LuBoxes}});
        // newRoutePainel({ title       :   "Webhooks",                url:controller+"/webhooks",     page:   PageWebhooks,    tema:true,  fase: 3,  login : true , menu:true  ,     sub:{ title       :   "Integrações"}});
       
    } 
    return painelRoutes;
}
import {  Flex, Image, Stack, Text,Icon,colorMode, toggleColorMode, useColorMode, Input, useToast} from "@chakra-ui/react"; 
import { ButtonKalyst } from "../../../components/button/ButtonKalyst";
import { LinkK } from "../../../components/button/LinkKalyst";
import { InputK } from "../../../components/input/InputKalyst";
import Typewriter from 'typewriter-effect';
import {   HK3, HK4 } from "../../../components/heading/HeadingKalyst";
import { SendForm } from "../../../components/form/SendFormAdmin";
import { object,string } from 'yup';
import { Helmet } from 'react-helmet-async';
import { TextK1 } from "../../../components/text/TextKalyst";
import { FaBeer } from "react-icons/fa"; 
import BoxDialog from "../../../components/modal/BoxDialog";
import { sendFormSocket, setSession } from "../../../components/session/SessionAdmin";
import { Navigate, Route } from "react-router-dom";
import { useState } from "react";
import { getWebsite } from "../../../theme/PageConfig"; 
import Box2Fa from "../../../components/2fa/Box2Fa";
export function PageAdminLogin({title}){
    const { colorMode } = useColorMode();
    const [redirect,setRedirect] = useState(null); 
    const [view2fa,setView2fa] = useState(null); 
    const [code,setCode] = useState(''); 
    const toast = useToast();
    return <Flex zIndex={2} w='100vw'  bg={'bgLoginDark.100'}  > 
            <Flex zIndex={3} w='full' align={'center'} justify='center' maxW={'1200px'}  minH={'100vh'}   margin = '0 auto' >
                <Flex w={{base:'600px',lg:'1200px'}} maxW = 'calc(100%)' minH={{base:'auto',md:'80%'}}    borderRadius={'5px'}> 
                    <Stack w={{base:'calc(100% - 40px)',lg:'50%'}} backdropFilter='blur(60px)'  borderRadius={'16px'}   align={'center'} justify='center'  m={'0 auto'} > 
                        <Stack w='calc(100% - 40px)' align={'center'} justify='center' maxW={'440px'}  gap={'15px'}  py={{base:'30px'}} >
                            <SendForm  dados = {{modulo:'user',cmd:'login'}}
                                initial = {
                                    {
                                        login    :   '',
                                        senha    :   ''
                                    }
                                } 
                                schema={
                                    object({ 
                                        login:      string().required('E-mail é obrigatório!'),
                                        senha:   string().required('Senha é obrigatória')
                                    })
                                } 
                                callback={(result,form)=>{  
                                    if(result.hash == 'code'){
                                        setView2fa(<Box2Fa onSubmit={(chave)=>{  
                                            form.code = chave.code;
                                            if(form.code.length>5){
                                                sendFormSocket({  modulo:'user',cmd:'login',form:form,callback:(result)=>{     
                                                    if(result.erro){ 
                                                        toast({
                                                            title:result.titulo,
                                                            description: result.mensagem[0].mensagem,
                                                            status: 'error',
                                                            position:'top-right',
                                                            duration: 6000,
                                                            isClosable: true,
                                                        });   
                                                    }else{
                                                        setSession({token:result.hash.token,dados:result.hash.dados}); 
                                                        setRedirect(<Navigate to={'/admin/dashboard'} />);
                                                        setView2fa(null);
                                                    }    
                                                }}); 
                                            } 
                                        }} onClose={()=>{
                                            setView2fa(null);  
                                        }} titulo='Mostrar Chave'  variant={'admin'}
                                        />);  
                                    }else{
                                        setSession({token:result.hash.token,dados:result.hash.dados}); 
                                        setRedirect(<Navigate to={'/admin/dashboard'} />);
                                    } 
                                }}
                                gap={'15px'}
                                > 
                                <Image zIndex={1}  m={'0 auto'}  h='198px'  src={'/web/'+getWebsite().url+'/logo_menu_light.png'}    alt='login' opacity={1}  borderRadius={'5px'}/>
                                <HK4 pb={{base:'0px',md:'10px',lg:'20px'}} textAlign='center'>Painel Admin</HK4>    
                                <InputK  type='email' label="Email" name="login" placeholder="Digite seu email"    autoComplete={true}/>
                                <InputK type='password'  label="Senha" name="senha"  placeholder="Digite sua senha" variantBT="admin"    autoComplete={true}/>  
                                <ButtonKalyst w='full' variant={'admin'} >
                                    Fazer Login
                                </ButtonKalyst> 
                            </SendForm>
                        </Stack> 
                    </Stack> 
                </Flex>
            </Flex>
            {view2fa}
            {redirect}
    </Flex>
}